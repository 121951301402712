
































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Global, Props, User } from '@/store';
import TimeClock from '@/components/TimeClock.vue';
import CustomTimeClock from '@/components/CustomTimeClock.vue';
import PropUtils from '@/modules/PropUtils';
import Utils from '@/modules/Utils';
import moment from 'moment-timezone';
import { Prop, Watch } from 'vue-property-decorator';
import API, { Types } from '@/modules/API';
import PropEditor from '@/components/PropEditor.vue';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';

@Component({
    components: {
        TimeClock,
        CustomTimeClock,
        PropEditor,
        ConfirmDialog
    }
})
export default class CabinetScheduleDialog extends Vue {

    @Global.State('lang') lang;
    @Global.State('timezone') projectTimezone;
    @Global.State('dusk_dawn_data') dusk_dawn_data;
    @Props.State('list') projectProperties;
    @User.State('username') username;
    @User.State('project') project;

    @Prop() cabinet;

    dialog = true;
    clock_type = 'astral';
    start_time = '18:00';
    start_offset = '00:00';
    start_offset_sign = '+';
    end_time = '06:00';
    end_offset = '00:00';
    end_offset_sign = '+';
    schedule_active = false;
    turnOn = '';
    turnOff = '';
    disabled_color = 'rgba(0,0,0,0.12)';
    default_schedule = null;
    cabinet_schedule = null;
    loading = true;
    save_message = 'Are you sure you want to save changes?';
    error_msg = null;
    diff_time_msg = 'Start and end time must be different';
    show_time_msg = false;
    saveScheduleDialog = false;

    async mounted(){
        await this.setCurrentCabinetSchedule();
        this.loading = false;
    }

    async setCurrentCabinetSchedule(){
        this.setDefaultSchedule();
        let cabinet_schedule;
        try {
            const response = await API.get(Types.DEVICES, `${this.cabinet.id}/properties`, { name: 'meta.devices_schedule' });
            cabinet_schedule = (response && response.results && response.results[0] && response.results[0].value) || null;
        } catch (e) {
            cabinet_schedule = null;
        }
        this.cabinet_schedule = cabinet_schedule;
        
        if (this.cabinet_schedule && typeof this.cabinet_schedule === 'object' && Object.entries(this.cabinet_schedule).length){
            if (this.cabinet_schedule.is_astronomical){
                this.clock_type = 'astral';
                this.start_offset_sign = this.cabinet_schedule.dusk.slice(0, 1);
                this.start_offset = this.cabinet_schedule.dusk.slice(1);
                this.end_offset_sign = this.cabinet_schedule.dawn.slice(0, 1);
                this.end_offset = this.cabinet_schedule.dawn.slice(1);
            }else {
                this.clock_type = 'custom';
                this.start_time = Utils.convertUtcTimeToTimezone(this.cabinet_schedule.on, this.projectTimezone, 'HH:mm');
                this.end_time = Utils.convertUtcTimeToTimezone(this.cabinet_schedule.off, this.projectTimezone, 'HH:mm');
            }
            this.schedule_active = this.cabinet_schedule.active;
        }else if (this.default_schedule && typeof this.default_schedule === 'object' && Object.entries(this.default_schedule).length) {
            if (this.default_schedule.is_astronomical){
                this.clock_type = 'astral';
                this.start_offset_sign = this.default_schedule.dusk.slice(0, 1);
                this.start_offset = this.default_schedule.dusk.slice(1);
                this.end_offset_sign = this.default_schedule.dawn.slice(0, 1);
                this.end_offset = this.default_schedule.dawn.slice(1);
            }else {
                this.clock_type = 'custom';
                this.start_time = Utils.convertUtcTimeToTimezone(this.default_schedule.on, this.projectTimezone, 'HH:mm');
                this.end_time = Utils.convertUtcTimeToTimezone(this.default_schedule.off, this.projectTimezone, 'HH:mm');
            }
            this.schedule_active = this.default_schedule.active;
        }else {
            this.error_msg = 'Schedule cannot be set on the assigned devices';
        }

        if (!this.error_msg) {
            this.calculateTurnOn();
            this.calculateTurnOff();
        }
    }

    setDefaultSchedule(){
        const default_schedule = PropUtils.getProperty(this.projectProperties, 'cabinet.default_schedule');
        this.default_schedule = default_schedule ? default_schedule.value : null;
    }

    @Watch('start_offset')
    @Watch('start_offset_sign')
    calculateTurnOn(){
        this.turnOn = Utils.getAstronomicalTime(`${this.start_offset_sign}${this.start_offset}`, this.dusk_dawn_data.dusk_timestamp, this.dusk_dawn_data.dusk, this.projectTimezone);
    }

    @Watch('end_offset')
    @Watch('end_offset_sign')
    calculateTurnOff(){
        this.turnOff = Utils.getAstronomicalTime(`${this.end_offset_sign}${this.end_offset}`, this.dusk_dawn_data.dawn_timestamp, this.dusk_dawn_data.dawn, this.projectTimezone);
    }

    @Watch('start_time')
    @Watch('end_time')
    checkTimeIsDifferent(){
        this.show_time_msg = this.start_time === this.end_time;
    }

    getSunTime(times){
        const dusk = moment(times.dusk).tz(this.projectTimezone);
        const dawn = moment(times.dawn).tz(this.projectTimezone);
        const duskStart_timestamp = dusk.valueOf();
        const dawnStart_timestamp = dawn.valueOf(); 
        return [dusk, dawn, duskStart_timestamp, dawnStart_timestamp];
    }

    setCabinetSchedule(){
        const prop = {
          name: 'meta.devices_schedule',
          objectID: this.cabinet.id,
          objectType: Types.DEVICES
        };
        
        const schedule_property = {
            is_astronomical: this.clock_type === 'astral',
            active: this.schedule_active,
            updater: this.username,
            timezone: this.projectTimezone
        };

        if (schedule_property.is_astronomical){
            schedule_property['dusk'] = `${this.start_offset_sign}${this.start_offset}`; 
            schedule_property['dawn'] = `${this.end_offset_sign}${this.end_offset}`;
        }else {
            schedule_property['on'] = Utils.convertTimezoneTimeToUtc(this.start_time, 'HH:mm', this.projectTimezone);
            schedule_property['off'] = Utils.convertTimezoneTimeToUtc(this.end_time, 'HH:mm', this.projectTimezone);
        }

        (this.$refs.propEditor as PropEditor).save(
          schedule_property,
          prop,
          () => {
            this.updateRelatedCabinetDevices();
          },
          'Schedule was saved successfully'
        );
        if (this.$route.path.includes('cabinet-details')){
            this.$emit('updateSchedule');
        }
        this.$emit('close');
    }

    async updateRelatedCabinetDevices(){
        const url = 'cabinet_scheduler/';
        const body = {
            company_id: this.project.company,
            project_id: this.project.id
        };

        API.backendServicesAPI(url, 'POST', {}, body);
    }

    get clockTypes(){
        return [{text: this.$t('Astronomical'), value: 'astral'}, {text: this.$t('Custom'), value: 'custom'}];
    }
}
